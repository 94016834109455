import { styled, keyframes } from "@mui/material/styles";
import { BodyText } from "../../styles";
import { fadeIn } from "react-animations";

const fadeInAnimation = keyframes`${fadeIn}`;

export const ProjectSection = styled("section")(({ theme }) => ({
  marginBottom: theme.spacing(3),

  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(2),
  },
}));

export const SectionTitle = styled(BodyText)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  animation: `3s ${fadeInAnimation}`,

  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(2),
  },
}));
