import { styled } from "@mui/material/styles";
import { BoxContainer } from "../../styles";

export const PortfolioOverlay = styled(BoxContainer)(({ theme }) => ({
  position: "absolute",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  zIndex: 2,
  top: 0,
  opacity: 0,
  color: "#fff",
  width: "100%",
  height: "100%",
  transition: `${theme.transitions.create("opacity", {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.easeIn,
  })}`,
  backgroundColor: theme.palette.mode === "dark" ? "#3d6086" : "#545454",
}));
