import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const PageContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

export const BodyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  lineHeight: 1.3,
  align: "justify",
  fontWeight: 400,
  textAlign: "justify",
}));

export const HeadingText = styled(BodyText)(({ theme }) => ({
  lineHeight: theme.typography.h2.lineHeight,
  align: "inherit",
  fontWeight: 500,

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

export const BoxContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});
