import { styled } from "@mui/material/styles";
import { alpha, AppBar, IconButton } from "@mui/material";
import { BoxContainer } from "../../styles";

export const Offset = styled("div")(({ theme }) => ({
  minHeight: "3.8rem",

  [theme.breakpoints.down("sm")]: {
    minHeight: "3.5rem",
  },
}));

export const NavBarContainer = styled(AppBar)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#20283c" : theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

export const NavItemsContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

export const NavLinkItems = styled(BoxContainer)(({ theme }) => ({
  alignSelf: "center",
  "& a": {
    textDecoration: "none",
    borderRadius: `${theme.spacing(0.3)}`,
    margin: `${theme.spacing(1)}`,
    padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
    transition: `${theme.transitions.create("background-color", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeIn,
    })}`,

    "&:hover": {
      backgroundColor: `${alpha(theme.palette.secondary.main, 0.3)}`,
    },

    "&.active": {
      backgroundColor: `${alpha(theme.palette.secondary.main, 0.3)}`,
    },
  },
}));

export const NavItemButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
  svg: {
    fill:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.contrastText
        : "#8e8e8e",
    transition: `${theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    })}`,

    "&:hover": {
      transform: `scale(1.2)`,
    },
  },
}));
