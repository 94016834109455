import { Link, SvgIcon, useTheme } from "@mui/material";
import { useContext } from "react";
import { ProjectsContext } from "../../store/ProjectsContext";
import { BodyText, BoxContainer } from "../../styles";
import { ImageContainer, ModalContainer, ModalContent } from "./modal.styles";
import {
  TagComponent,
  TagsContainer,
} from "../HeroSection/HeroText/hero-text.styles";

export const ModalComponent = ({
  shouldShow,
  onRequestClose,
  projectId,
  Icon,
  itemUrl,
  itemName,
}) => {
  const theme = useTheme();
  const { useProject } = useContext(ProjectsContext);
  const project = useProject(projectId)[0];

  const fillColor = theme.palette.mode === "dark" ? "#FCFCFC" : "#212329";

  return (
    <ModalContainer
      open={shouldShow}
      onClose={onRequestClose}>
      <ModalContent>
        <ImageContainer>
          <img
            src={project.projectImage}
            alt={project.projectTitle}
          />
        </ImageContainer>
        <BoxContainer>
          <BodyText
            id="modal-modal-title"
            variant="h6"
            component="h2">
            {project.projectTitle}
          </BodyText>
          <Link
            href={itemUrl}
            alt={itemName}
            target="_blank"
            rel="noopener">
            <SvgIcon
              fontSize="small"
              component={Icon}
              inheritViewBox
              sx={{ fill: fillColor }}
            />
          </Link>
        </BoxContainer>
        <BoxContainer
          sx={{
            flexDirection: "column",
          }}>
          <BoxContainer>
            <TagsContainer modal="true">
              {project.projectTags.map((tag, idx) => (
                <TagComponent
                  sx={{
                    border: "1px solid #2B4552",
                    color: fillColor,
                    padding: "8px",
                  }}
                  key={idx}>
                  {tag}{" "}
                </TagComponent>
              ))}
            </TagsContainer>
          </BoxContainer>
          <BodyText
            id="modal-modal-description"
            sx={{
              whiteSpace: "pre-wrap",
            }}>
            {project.projectDescription}
          </BodyText>
        </BoxContainer>
      </ModalContent>
    </ModalContainer>
  );
};
