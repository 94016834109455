import { keyframes } from "@mui/material/styles";
import { fadeIn, fadeInDown, merge } from "react-animations";

import PageContainerComponent from "../containers/PageContainerComponent";
import AnchorLink from "../components/AnchorLink";

import { BodyText, HeadingText } from "../styles";
import { HeroContainer } from "../components/HeroSection/hero-section.styles";

const animations = merge(fadeIn, fadeInDown);
const fadeInAndDownAnimation = keyframes`${animations}`;

export const About = () => (
  <>
    <HeroContainer />
    <PageContainerComponent>
      <HeadingText
        variant="h2"
        sx={{ animation: `2s ${fadeInAndDownAnimation}` }}>
        About Me
      </HeadingText>
      <br />
      <BodyText
        component="p"
        sx={{ animation: `2s ${fadeInAndDownAnimation}` }}>
        I am a full-stack developer based in Houston, TX and currently working
        freelance. I have a BSc in Computer Science and background experience in
        business analytics which has given an edge to my software development
        process and instilled a customer-centric approach to how I code. Over
        the last 3-4 years, my toolbox of languages and technologies has grown.
        My proficiency includes the following (among others): Python, Django,
        C#, Xamarin (.Net Maui), Dart, Flutter, JavaScript and React.
      </BodyText>
      <br />
      <BodyText
        component="p"
        sx={{ animation: `2s ${fadeInAndDownAnimation}` }}>
        In my own journey as a developer, I have discovered my passion for
        learning as I continually grow my skills. I am therefore passionate
        about EdTech and focusing my career on developing solutions that enhance
        student learning.
      </BodyText>
      <br />
      <BodyText
        component="p"
        sx={{ animation: `2s ${fadeInAndDownAnimation}` }}>
        While my friends would describe me as a nerd and avid tech guy, there
        are sides of myself I have come to discover beyond the two walls that
        make up my ‘tech corner office’. I love nature and the outdoors. Hiking,
        and more recently trail walking, are some of my favorite pastimes. An
        escape from the noise of life and I find myself often relishing such
        moments. I’ve also come to realize my affinity for nature and the quiet
        through my love for long lazy drives in open spaces- no google maps or
        nav system, just a chance to enjoy the ride. And some country music to
        sweeten the ride of course!
      </BodyText>
      <br />
      <BodyText
        component="p"
        sx={{ animation: `2s ${fadeInAndDownAnimation}` }}>
        Beyond coding and tech, I think I would have become a theoretical
        physicist or philosopher. I’ve always been attracted to intelligent
        thinkers with one of my favorites being Rene Descartes. I am an avid
        reader and some books I’ve enjoyed are ‘The Enigma of Reason’ and
        ‘Sparks of Genius’. I must say I thoroughly enjoy the thought process
        and compelling arguments from these two sources. Maybe someday I will
        explore the idea of penning my ‘intelligent’ thoughts too.
      </BodyText>
      <br />
      <BodyText
        component="p"
        sx={{
          animation: `2s ${fadeInAndDownAnimation}`,
          marginBottom: "3rem",
        }}>
        Connect with me on{" "}
        <AnchorLink
          linkTo="https://www.linkedin.com/in/williamchavula/"
          linkName="LinkedIn"
        />{" "}
        or{" "}
        <AnchorLink
          linkTo="mailto:rumbani@gmail.com"
          linkName="Email"
        />
        . I'd love to chat.
      </BodyText>
    </PageContainerComponent>
  </>
);
