import { styled } from "@mui/material/styles";
import { Link } from "@mui/material";

export const Anchor = styled(Link)(({ theme, nostyles }) => ({
  color: nostyles ? "#FCFCFC" : "#165eac",
  borderBottom: nostyles
    ? "none"
    : `1px dashed ${theme.palette.primary.contrastText}`,
  textDecoration: "none",
}));
