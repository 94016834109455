import { FooterContainer, FooterContentContainer } from "./footer.styles";

import { NavLink } from "react-router-dom";
import { Grid, Link } from "@mui/material";

import logoLight from "../../resources/images/logo_light.png";
import { ReactComponent as Contact } from "../../resources/assets/envelope-paper.svg";
import { ReactComponent as LinkedInIcon } from "../../resources/assets/linkedin.svg";
import { ReactComponent as GitHubIcon } from "../../resources/assets/github.svg";

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterContentContainer maxWidth="md">
        <Grid
          container
          alignItems="center"
          justifyContent="center">
          <Grid
            xs={4}
            md={3}
            container
            item
            direction="column"
            alignItems="start"
            justifyContent="center">
            <Grid item>
              <NavLink to="/">
                <img
                  style={{ width: "60px", height: "45px" }}
                  src={logoLight}
                  alt="Dark Mode Logo"
                />
              </NavLink>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}>
            <FooterContentContainer>
              <Link
                href="mailto:rumbani@gmail.com"
                alt="Email"
                target="_blank"
                rel="noopener"
                sx={{ color: "#547fad" }}>
                <Contact fill="#547fad" />
              </Link>
              <Link
                href="https://www.linkedin.com/in/williamchavula"
                alt="LinkedIn"
                target="_blank"
                rel="noopener">
                <LinkedInIcon fill="#547fad" />
              </Link>
              <Link
                href="https://github.com/WilliamChavula?tab=repositories"
                alt="Github"
                target="_blank"
                rel="noopener">
                <GitHubIcon fill="#547fad" />
              </Link>
            </FooterContentContainer>
          </Grid>
        </Grid>
      </FooterContentContainer>
    </FooterContainer>
  );
};
