import { styled } from "@mui/material/styles";
import { BoxContainer, HeadingText } from "../../styles";

export const NavLinkText = styled(HeadingText)(({ theme }) => ({
  color:
    theme.palette.mode === "dark"
      ? theme.palette.secondary.contrastText
      : "#43779f",
  fontSize: "1rem",
  verticalAlign: "baseline",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.15rem",
  },
}));

export const NavItem = styled(BoxContainer)(({ theme }) => ({
  svg: {
    fill:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.contrastText
        : "#43779f",
    fontSize: "0.9rem",
    alignSelf: "center",
    marginRight: `${theme.spacing(1)}`,

    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
      marginBottom: theme.spacing(1),
    },
  },
}));
