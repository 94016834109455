import { SvgIcon } from "@mui/material";

import { NavLink } from "react-router-dom";
import { NavItem, NavLinkText } from "./nav-item.styles";

const NavItemComponent = ({ Icon, text, to, closeMenuHandler }) => (
  <NavLink to={to}>
    <NavItem onClick={closeMenuHandler}>
      <SvgIcon
        fontSize="small"
        component={Icon}
        inheritViewBox
      />
      <NavLinkText variant="h6">{text}</NavLinkText>
    </NavItem>
  </NavLink>
);

export default NavItemComponent;
