import { styled } from "@mui/material/styles";
import { Box, Modal } from "@mui/material";

// export const ModalBackground = styled("div")({
//   position: "fixed",
//   zIndex: 1,
//   left: 0,
//   top: 0,
//   width: "80%",
//   height: "80%",
//   overflow: "auto",
//   backgroundColor: "rgba(0, 0, 0, 0.5)",
// });
//
// export const ModalBody = styled("div")(({ theme }) => ({
//   backgroundColor: "white",
//   margin: "10% auto",
//   padding: theme.spacing(3),
//   width: "50%",
// }));

export const ModalContainer = styled(Modal)(({ theme }) => ({
  position: "fixed",
  // top: "10%",
  left: "0%",
  margin: "auto auto",
  paddingTop: theme.spacing(8),

  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(3),
    left: "0%",
    right: "0%",
    textAlign: "justify",
    transform: "none",
  },
  // width: "80vw",
  // height: "auto",
  // margin: "auto auto",
  //
  // [`${theme.breakpoints.between("md", "lg")} and (orientation: landscape)`]: {
  //   width: "70vw",
  // },
  //
  // [theme.breakpoints.down("md")]: {
  //   width: "85vw",
  //
  //   img: {
  //     width: "95vw",
  //   },
  // },
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  width: "55%",
  margin: "auto",
  maxHeight: "100%",
  backgroundColor: theme.palette.mode === "dark" ? "#212329" : "#FCFCFC",
  border: "1px solid #808080",
  borderRadius: theme.spacing(1 / 2),
  boxShadow: theme.shadows[1],
  padding: theme.spacing(4),
  textAlign: "justify",
  marginBottom: theme.spacing(2),
  overflowY: "auto",

  [theme.breakpoints.down("lg")]: {
    width: "90%",
    padding: theme.spacing(3),

    h2: {
      marginBottom: theme.spacing(2),
      fontSize: "1.7rem",
    },

    svg: {
      fontSize: "20px",
    },

    p: {
      fontSize: "1rem",
    },
  },

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),

    h2: {
      fontSize: "1.3rem",
    },

    svg: {
      fontSize: "1.14rem",
    },

    p: {
      fontSize: "0.9rem",
    },
  },
}));

export const ImageContainer = styled(Box)(({ theme }) => ({
  height: "28.571rem",
  marginBottom: theme.spacing(3),

  [`${theme.breakpoints.down("lg")}  and (orientation: landscape)`]: {
    display: "none",
  },

  [theme.breakpoints.down("sm")]: {
    height: "12.857rem",
  },

  img: {
    objectFit: "cover",
    display: "block",
    width: "100%",
    height: "100%",
    borderRadius: theme.spacing(1 / 2),
  },
}));
