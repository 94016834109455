// noinspection SpellCheckingInspection

import { useEffect, useRef } from "react";

import Typed from "typed.js";
import {
  SpanComponent,
  TagBoxContainer,
  TagComponent,
  TagsContainer,
  TagText,
} from "./hero-text.styles";
import { Button, Fade, useMediaQuery } from "@mui/material";
import { ReactComponent as DownloadIcon } from "../../../resources/assets/download.svg";
import AnchorLink from "../../AnchorLink";

import resume from "../../../resources/files/Resume - William Chavula.pdf";

const intro = [
  "Hello! My name is William.",
  "",
  "Hola! Mi nombre es William.",
  "",
  "Bonjour! Je m’appelle William.",
  "",
  "Moni! Dzina langa ndi William.",
];

const tagsDeskTop = [
  "Python",
  "JavaScript",
  "Dart",
  "C#",
  "Django",
  "Rest APIs",
  "React",
  "Flutter",
  "Xamarin",
  ".Net Maui",
  "Unit testing",
  "Docker",
  "Azure DevOps",
  "Github",
];

const tagsMobile = [
  "Web Development",
  "Mobile Development",
  "Unit testing",
  "Docker",
  "Azure DevOps",
  "Github",
];

export const HeroText = () => {
  const targetElement = useRef(null);
  const typedElement = useRef(null);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const tags = isMobile ? tagsMobile : tagsDeskTop;

  useEffect(() => {
    const options = {
      strings: intro,
      typeSpeed: 50,
      startDelay: 600,
      backSpeed: 50,
      loop: true,
    };

    typedElement.current = new Typed(targetElement.current, options);
    return () => {
      typedElement.current.destroy();
    };
  }, []);

  return (
    <TagBoxContainer>
      <div>
        <SpanComponent ref={targetElement} />
        <TagText>I solve problems using code</TagText>
        <TagsContainer>
          {tags.map((tag, idx) => (
            <TagComponent key={idx}>{tag} </TagComponent>
          ))}
        </TagsContainer>
        <Fade
          in={true}
          timeout={1500}>
          <Button
            variant="text"
            startIcon={<DownloadIcon />}
            sx={{ color: "#FCFCFC" }}>
            <AnchorLink
              linkTo={resume}
              linkName="Download my resume"
              sx={{ color: "#547fad" }}
              nostyles="true"
            />
          </Button>
        </Fade>
      </div>
    </TagBoxContainer>
  );
};
