import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
  palette: {
    mode: "light",
    background: {
      default: "#FCFCFC",
    },
    primary: {
      main: "#FCFCFC",
      contrastText: "#0E1429",
    },
    secondary: {
      main: "#6094bc",
      contrastText: "#FCFCFC",
    },

    error: {
      main: "#df2810",
      light: "#84213A",
      dark: "#5A192E",
    },
  },
  typography: {
    fontFamily: "Source Sans Pro, sans-serif",
  },
});

export const darkTheme = createTheme(lightTheme, {
  palette: {
    mode: "dark",
    background: {
      default: "#0E1429",
    },
    primary: {
      main: "#0E1429",
      contrastText: "#FCFCFC",
    },
  },
});
