// noinspection SpellCheckingInspection
import tidyeImage from "../images/tidye-project-image.jpeg";
import cardMatchImage from "../images/card-match-project-image.jpeg";
import jeremyProjectImage from "../images/jeremy-project-image.jpeg";
import stashrProjectImage from "../images/stashr-project-image.jpeg";
import expressProjectImage from "../images/express-project-image.jpeg";
import fastApiProjectImage from "../images/fastapi-project-image.jpeg";

import { v4 as uuidv4 } from "uuid";

export const portfolioProjects = () => [
  {
    projectId: uuidv4(),
    projectTitle: "Tidye",
    projectOverview:
      "A first of its kind restaurant catalog in Malawi. Curating over 40 restaurants across several cuisines.",
    projectImage: tidyeImage,
    projectTags: [
      "flutter",
      "firebase firestore",
      "firebase storage",
      "dart",
      "android",
      "bloc",
    ],
    projectDescription:
      "Tidye means 'let us eat' in the local dialect. The idea for this app came after noticing that there was no single platform available on the Malawian market where food lovers could go, search, and discover Malawi's diverse food scene.\n" +
      "It features over 40 restaurants that serve different cuisines, both local and international.\n\n" +
      "The app was developed using Flutter and utilized Firebase for the backend. Cloud Firestore contains all the restaurant data, while restaurant logos and images are stored in Firebase.\n\n" +
      "The app is currently only available on Android where it has 100+ downloads.",
    projectLinks: [
      {
        hostType: "playstore",
        projectUrl:
          "https://play.google.com/store/apps/details?id=com.quivrdevelopers.meals_app",
      },
    ],
  },
  {
    projectId: uuidv4(),
    projectTitle: "Card Match",
    projectOverview:
      "A fun and simple brainteaser to keep you entertained. Watch out though, you might get hooked!",
    projectImage: cardMatchImage,
    projectTags: [
      "android",
      "sqlite",
      "firebase",
      "xamarin forms",
      "C#",
      "mvvm",
    ],
    projectDescription:
      "I collaborated with a colleague to develop this game after noticing that Covid shutdowns had reduced human interactions and left many people isolated, with so much time on their hands. " +
      "The game was developed as a way to keep users entertained and has multiple stages to keep them engaged.\n\n" +
      "We utilized Xamarin Forms and C# to develop the app. My contribution to the project included coding the UI, writing some backend logic for user authentication, and implementing the leaderboard scores logic.\n\n" +
      "The app is currently only available on Android.",
    projectLinks: [
      {
        hostType: "playstore",
        projectUrl:
          "https://play.google.com/store/apps/details?id=com.muona.card_match&hl=en&gl=US",
      },
    ],
  },
  {
    projectId: uuidv4(),
    projectTitle: "JEPayments.com",
    projectOverview:
      "A portfolio site for a client that works with businesses, helping them set up convenient payment solutions.",
    projectImage: jeremyProjectImage,
    projectTags: ["react", "material-ui", "SPA", "javascript"],
    projectDescription:
      "A client in the Payment solutions business approached me to help them set up their portfolio site in order to increase their online presence.\n\n" +
      "The result, jepayments.com. The site features a clean UI, simple and elegant component transitions to engage and interact with the users.\n" +
      "The website was developed with React and Material UI",
    projectLinks: [
      { hostType: "web", projectUrl: "https://www.jepayments.com/" },
    ],
  },
  {
    projectId: uuidv4(),
    projectTitle: "Stashr",
    projectOverview:
      "An intuitive and easy-to-use personal finance management app.",
    projectImage: stashrProjectImage,
    projectTags: [
      "sqlite",
      "C#",
      "android",
      "mobile app",
      "xamarin forms",
      "mvvm",
    ],
    projectDescription:
      "The idea for the app was birthed with college students and recent graduates in mind. However, anyone who wants to have a better handle on their finances can use it.\n\n" +
      "The app gives users a way to track their expenses, income, and savings. By logging any kind of transaction they've made, the app will show them how healthy their finances are. " +
      "The hope is that with such information, users will develop better financial habits.\n\n" +
      "Stashr was developed with Xamarin Forms. User data is stored in an sqlite database that the app comes with. The app follows the MVVM pattern and uses prism library for implementation.\n\n" +
      "The app is currently only available on Android.",
    projectLinks: [
      {
        hostType: "playstore",
        projectUrl:
          "https://play.google.com/store/apps/details?id=com.williamroosevelt.personalfinancemanager",
      },
    ],
  },
];

export const honorableMentionsPortfolioProjects = () => [
  {
    projectId: uuidv4(),
    projectTitle: "API Project with ExpressJs",
    projectOverview:
      "A sample API application using express and mongodb for an e-learning platform.",
    projectImage: expressProjectImage,
    projectTags: [
      "express",
      "api",
      "mongodb",
      "javascript",
      "mongoose",
      "docker",
      "jest",
    ],
    projectDescription:
      "This sample portfolio API project was developed using JavaScript, ExpressJS and Mongodb with mongoose ORM. " +
      "The app employs a microservices architecture using docker. The app services are: The API, MongoDb Database, and mongo-express GUI Database management tool.\n\n" +
      "In addition to the standard endpoints for retrieving lists and single items, the API also includes authentication and Bearer Token authorization implemented through middleware functions. " +
      "The project also includes unit tests and integration tests with Jest.",
    projectLinks: [
      {
        hostType: "github",
        projectUrl: "https://github.com/WilliamChavula/courses_express_api",
      },
    ],
  },
  {
    projectId: uuidv4(),
    projectTitle: "API Project with FastAPI",
    projectOverview:
      "A sample API application using FastAPI and postgresql for an e-learning platform.",
    projectImage: fastApiProjectImage,
    projectTags: [
      "docker",
      "python",
      "fastapi",
      "sqlalchemy",
      "alembic",
      "postgresql",
      "pytest",
    ],
    projectDescription:
      "This sample portfolio API project was developed using Python and FastAPI web framework, Postgresql with SQLAlchemy ORM and alembic to manage database migrations. " +
      "The app employs a microservices architecture using docker. The app services are: The API, Postgres Database, and PGAdmin4 GUI Database management  tool.\n\n" +
      "In addition to the standard endpoints for retrieving lists and single items, the API also includes authentication and Bearer Token authorization implemented through dependency injection on route handlers. " +
      "The project also includes unit tests and integration tests with Pytest.",
    projectLinks: [
      {
        hostType: "github",
        projectUrl: "https://github.com/WilliamChavula/courses_fast_api",
      },
    ],
  },
];
