import { createContext, useMemo } from "react";

import {
  portfolioProjects,
  honorableMentionsPortfolioProjects,
} from "../../resources/data/projects";

export const ProjectsContext = createContext({
  projects: [],
  honorableMentions: [],
});

export default function ProjectsContextProvider({ children }) {
  const projects = useMemo(() => portfolioProjects(), []);
  const honorableMentions = useMemo(
    () => honorableMentionsPortfolioProjects(),
    []
  );
  const allProjects = [...projects, ...honorableMentions];
  const useProject = projectId =>
    allProjects.filter(project => project.projectId === projectId);

  return (
    <ProjectsContext.Provider
      value={{ projects, honorableMentions, useProject }}>
      {children}
    </ProjectsContext.Provider>
  );
}
