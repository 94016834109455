import { useState } from "react";
import { Button, Link, SvgIcon } from "@mui/material";

import { PortfolioOverlay } from "./overlay.styles";

import { ReactComponent as ReadMoreIcon } from "../../resources/assets/read-more.svg";
import { ModalComponent } from "../ModalComponent";

export const OverlayComponent = ({ Icon, itemUrl, itemName, projectId }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = evt => {
    evt.preventDefault();
    setShowModal(showModal => !showModal);
  };
  return (
    <PortfolioOverlay>
      <Link
        href={itemUrl}
        alt={itemName}
        target="_blank"
        rel="noreferrer">
        <SvgIcon
          fontSize="small"
          component={Icon}
          inheritViewBox
          sx={{ fill: "#FCFCFC" }}
        />
      </Link>
      <Button
        variant="text"
        startIcon={<ReadMoreIcon />}
        onClick={toggleModal}
        sx={{ color: "#FCFCFC" }}>
        Read more
      </Button>
      <ModalComponent
        onRequestClose={toggleModal}
        shouldShow={showModal}
        projectId={projectId}
        Icon={Icon}
        itemUrl={itemUrl}
        itemName={itemName}
      />
    </PortfolioOverlay>
  );
};
