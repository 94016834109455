import React from "react";
import { Anchor } from "./anchor-link.styles";

const AnchorLink = ({ linkName, linkTo, nostyles = null }) => (
  <Anchor
    href={linkTo}
    nostyles={nostyles}
    target="_blank"
    rel="noopener">
    {linkName}
  </Anchor>
);

export default AnchorLink;
