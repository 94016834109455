import { styled } from "@mui/material/styles";

import { alpha, Container, Popover } from "@mui/material";
import { NavLinkItems } from "./navbar.styles";

export const MobileNavItemsContainer = styled(Container)(({ theme }) => ({
  display: "block",
  width: "100%",
  padding: theme.spacing(1),

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "div svg": {
      fontSize: "1.2rem",
    },
  },
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

export const MobileNavLinkItems = styled(NavLinkItems)(({ theme }) => ({
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(1),

  "&:last-child": {
    marginBottom: theme.spacing(2),
  },
}));

export const MobilePopOver = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.mode === "dark" ? "#0E1429" : "#FCFCFC",
    borderRadius: theme.spacing(1),
    border: `2px solid ${alpha(theme.palette.secondary.main, 0.3)}`,
    backgroundImage: "none",
  },
}));
