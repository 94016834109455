import { CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom";

import AppColorThemeContextProvider from "../store/ThemeContext";
import ProjectsContextProvider from "../store/ProjectsContext";
import ScrollToTop from "../components/ScrollToTop";

import { Landing } from "./Landing";
import { About } from "./About";
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";

function App() {
  return (
    <ProjectsContextProvider>
      <AppColorThemeContextProvider>
        <CssBaseline />
        <NavBar />
        <ScrollToTop>
          <Routes>
            <Route
              path="/"
              element={<Landing />}
            />
            <Route
              path="about"
              element={<About />}
            />
          </Routes>
        </ScrollToTop>
        <Footer />
      </AppColorThemeContextProvider>
    </ProjectsContextProvider>
  );
}

export default App;
