import { useState } from "react";
import { Box, Link } from "@mui/material";
import { NavLink } from "react-router-dom";

import NavItemComponent from "../NavItem";

import { NavItemButton } from "./navbar.styles";
import {
  MobileNavItemsContainer,
  MobileNavLinkItems,
  MobilePopOver,
} from "./navbar.mobile.styles";

import { ReactComponent as HomeIcon } from "../../resources/assets/house-door.svg";
import { ReactComponent as AboutIcon } from "../../resources/assets/person.svg";
import { ReactComponent as Contact } from "../../resources/assets/envelope-paper.svg";
import { ReactComponent as SunIcon } from "../../resources/assets/brightness-high-fill.svg";
import { ReactComponent as MoonIcon } from "../../resources/assets/moon-stars-fill.svg";
import { ReactComponent as HamburgerIcon } from "../../resources/assets/hamburger_menu.svg";
import { ReactComponent as CloseIcon } from "../../resources/assets/close_button.svg";
import logoLight from "../../resources/images/logo_light.png";
import { ReactComponent as LinkedInIcon } from "../../resources/assets/linkedin.svg";
import { ReactComponent as GitHubIcon } from "../../resources/assets/github.svg";

const NavbarMobile = ({
  isDark,
  switchTheme,
  handleMenuClick,
  menuDisplay,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleMenuToggle = () => setOpen(prev => !prev);
  const handlePopOver = event => {
    setAnchorEl(event.currentTarget);
    handleMenuToggle();
    handleMenuClick();
  };

  // noinspection JSValidateTypes
  return (
    <MobileNavItemsContainer>
      <Box component="div">
        <NavLink to="/">
          <img
            style={{ width: "3.57rem", height: "2.5rem" }}
            src={logoLight}
            alt="Dark Mode Logo"
          />
        </NavLink>
        <div>
          <NavItemButton
            aria-label="Open Menu"
            onClick={handlePopOver}>
            {!open ? (
              <HamburgerIcon
                style={{ width: "1.714rem", height: "1.714rem" }}
              />
            ) : (
              <CloseIcon style={{ width: "1.714rem", height: "1.714rem" }} />
            )}
          </NavItemButton>
          <MobilePopOver
            open={open}
            anchorEl={anchorEl}
            placement="bottom-end"
            onClose={handleMenuToggle}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}>
            <MobileNavLinkItems display={menuDisplay}>
              <NavItemComponent
                to="/"
                Icon={HomeIcon}
                text="Home"
                closeMenuHandler={handleMenuToggle}
              />
              <NavItemComponent
                to="about"
                Icon={AboutIcon}
                text="About"
                closeMenuHandler={handleMenuToggle}
              />
              <Link
                href="mailto:rumbani@gmail.com"
                alt="Email"
                target="_blank"
                rel="noopener"
                sx={{ color: "#547fad" }}>
                <Contact fill="#547fad" />
              </Link>
              <Link
                href="https://www.linkedin.com/in/williamchavula"
                alt="LinkedIn"
                target="_blank"
                rel="noopener">
                <LinkedInIcon fill="#547fad" />
              </Link>
              <Link
                href="https://github.com/WilliamChavula?tab=repositories"
                alt="Github"
                target="_blank"
                rel="noopener">
                <GitHubIcon fill="#547fad" />
              </Link>
              <NavItemButton
                aria-label="switch theme"
                onClick={() => {
                  switchTheme();
                  handleMenuToggle();
                }}>
                {isDark ? <SunIcon /> : <MoonIcon />}
              </NavItemButton>
            </MobileNavLinkItems>
          </MobilePopOver>
        </div>
      </Box>
    </MobileNavItemsContainer>
  );
};

export default NavbarMobile;
