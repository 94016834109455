import { alpha, Card, CardContent, CardMedia } from "@mui/material";

import { styled, keyframes } from "@mui/material/styles";

import { fadeIn } from "react-animations";

const fadeInAnimation = keyframes`${fadeIn}`;

export const PortfolioCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#212329" : "#FCFCFC",
  position: "relative",
  cursor: "pointer",

  borderRadius: theme.spacing(0.4),
  border:
    theme.palette.mode === "dark"
      ? "none"
      : `${theme.spacing(1 / 8)} solid ${alpha(
          theme.palette.secondary.main,
          0.65
        )}`,
  boxShadow:
    theme.palette.mode === "dark"
      ? "none"
      : "0px 2px 1px -1px rgba(33,35,41,0.2), 0px 1px 1px 0px rgba(33,35,41,0.14), 0px 1px 3px 0px rgba(33,35,41,0.12)",

  animation: `3s ${fadeInAnimation}`,

  "&:hover .MuiBox-root": {
    opacity: "0.8",
  },
}));

export const PortfolioCardMedia = styled(CardMedia)({
  position: "relative",
  height: "10rem",
});

export const PortfolioCardContent = styled(CardContent)(({ theme }) => ({
  "h5, p": {
    color: theme.palette.mode === "dark" ? "#cccfd6" : "#2e3b4c",
  },
}));
