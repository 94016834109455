import { HeroText } from "./HeroText";
import { HeroContainer } from "./hero-section.styles";
import { Container } from "@mui/material";

export const HeroSectionComponent = () => (
  <HeroContainer>
    <Container maxWidth="md">
      <HeroText />
    </Container>
  </HeroContainer>
);
