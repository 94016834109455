import React, { createContext, useState } from "react";
import { ThemeProvider, useMediaQuery } from "@mui/material";

import { darkTheme, lightTheme } from "../../theme";

export const AppColorThemeContext = createContext({ switchTheme: () => {} });

export default function AppColorThemeContextProvider({ children }) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const preferredAppTheme = prefersDarkMode ? darkTheme : lightTheme;

  const [appTheme, setAppTheme] = useState(preferredAppTheme);
  const [isDark, setIsDark] = useState(prefersDarkMode);

  const switchTheme = () => {
    setAppTheme(appTheme === darkTheme ? lightTheme : darkTheme);
    setIsDark(isDark => !isDark);
  };
  return (
    <AppColorThemeContext.Provider value={{ switchTheme, isDark }}>
      <ThemeProvider theme={appTheme}>{children}</ThemeProvider>
    </AppColorThemeContext.Provider>
  );
}
