import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
import { BoxContainer } from "../../styles";

export const FooterContainer = styled(BoxContainer)(({ theme }) => ({
  height: "4rem",
  backgroundColor:
    theme.palette.mode === "dark" ? "#20283c" : theme.palette.primary.main,

  boxShadow:
    theme.palette.mode === "dark"
      ? "none"
      : "0px -0.8px 1px 1px rgba(0,0,0, 20%)",
}));

export const FooterContentContainer = styled(Container)(({ theme }) => ({
  a: {
    display: "inline-block",
    margin: theme.spacing(1),
  },
}));
