import { styled, keyframes } from "@mui/material/styles";

import { fadeInLeft, fadeInDown } from "react-animations";

import { BodyText, BoxContainer } from "../../../styles";

const fadeInLeftAnimation = keyframes`${fadeInLeft}`;
const fadeInDownAnimation = keyframes`${fadeInDown}`;

export const TagBoxContainer = styled(BoxContainer)(({ theme }) => ({
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(3),
  },
}));

export const SpanComponent = styled("span")(({ theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  background: "-webkit-linear-gradient(45deg, #6094bc 30%, #718b8f 90%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  marginBottom: theme.spacing(5),

  [theme.breakpoints.down("md")]: {
    fontSize: theme.typography.h5.fontSize,
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: theme.typography.h6.fontSize,
    marginBottom: theme.spacing(2),
  },
}));

export const TagsContainer = styled("div")(({ theme, modal }) => ({
  maxWidth: "35.7rem",

  [theme.breakpoints.down("md")]: {
    maxWidth: "32.14rem",
  },

  [theme.breakpoints.down("sm")]: {
    maxWidth: "17.857rem",
    marginBottom: modal ? theme.spacing(1) : theme.spacing(3),
  },
}));

export const TagComponent = styled("span")(({ theme }) => ({
  display: "inline-block",
  height: "2.5rem",
  color: theme.palette.secondary.contrastText,
  fontSize: "0.893rem",
  border: "2px solid #2B4552",
  borderRadius: "2px",
  margin: theme.spacing(1 / 2),
  padding: theme.spacing(1 / 2),
  animation: `2s ${fadeInLeftAnimation}`,

  [theme.breakpoints.down("sm")]: {
    height: "1.429rem",
    fontSize: "0.714rem",
    margin: theme.spacing(1 / 4),
    padding: theme.spacing(1 / 4),
  },
}));

export const TagText = styled(BodyText)(({ theme }) => ({
  fontSize: "1.143rem",
  fontStyle: "italic",
  marginBottom: theme.spacing(2),
  color: theme.palette.secondary.contrastText,
  animation: `1s ${fadeInDownAnimation}`,

  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.857rem",
  },
}));
