import { styled } from "@mui/material/styles";
import heroImage from "../../resources/images/hero_image.png";

export const HeroContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundImage: `url(${heroImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  width: "100%",
  height: "32rem",

  [theme.breakpoints.down("md")]: {
    height: "auto",
    minHeight: "20rem",
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: "15rem",
  },
}));
