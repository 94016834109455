import { BodyText, HeadingText } from "../../styles";
import {
  PortfolioCard,
  PortfolioCardContent,
  PortfolioCardMedia,
} from "./portfolio-item.styles";

import { OverlayComponent } from "../Overlay";
import { ReactComponent as DownLoadIcon } from "../../resources/assets/download.svg";
import { ReactComponent as GitHubIcon } from "../../resources/assets/github.svg";
import { ReactComponent as GlobeIcon } from "../../resources/assets/globe.svg";

const PortfolioItem = ({
  bgImage,
  cardTitle,
  cardBody,
  projectLinksArray,
  projectId,
}) => {
  const iconsMapper = {
    playstore: DownLoadIcon,
    github: GitHubIcon,
    web: GlobeIcon,
  };
  return (
    <PortfolioCard variant="outlined">
      <PortfolioCardMedia
        component="img"
        image={bgImage}
        alt={`${cardTitle} Portfolio Project`}
      />
      <PortfolioCardContent>
        <HeadingText
          gutterBottom
          variant="h5">
          {cardTitle}
        </HeadingText>
        <BodyText variant="body2">{cardBody}</BodyText>
      </PortfolioCardContent>
      {projectLinksArray.map((item, idx) => (
        <OverlayComponent
          key={idx}
          Icon={iconsMapper[item.hostType]}
          itemName={cardTitle}
          itemUrl={item.projectUrl}
          projectId={projectId}
        />
      ))}
    </PortfolioCard>
  );
};

export default PortfolioItem;
