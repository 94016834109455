import { useContext } from "react";

import { ProjectsContext } from "../store/ProjectsContext";

import PageContainerComponent from "../containers/PageContainerComponent";
import { HeroSectionComponent } from "../components/HeroSection";
import { SectionComponent } from "../components/Section";

export const Landing = () => {
  const { projects, honorableMentions } = useContext(ProjectsContext);
  return (
    <>
      <HeroSectionComponent />
      <PageContainerComponent>
        <SectionComponent
          sectionTitle="Projects"
          projectsArray={projects}
          variant="h4"
        />
        <SectionComponent
          sectionTitle="Cool Repos"
          projectsArray={honorableMentions}
          variant="h5"
        />
      </PageContainerComponent>
    </>
  );
};
