import { useContext, useState, useCallback } from "react";
import { Link, Toolbar, useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";

import NavItemComponent from "../NavItem";
import { AppColorThemeContext } from "../../store/ThemeContext";

import logoLight from "../../resources/images/logo_light.png";
import {
  NavBarContainer,
  NavItemButton,
  NavItemsContainer,
  NavLinkItems,
  Offset,
} from "./navbar.styles";
import NavbarMobile from "./index.mobile";

import { ReactComponent as AboutIcon } from "../../resources/assets/person.svg";
import { ReactComponent as Contact } from "../../resources/assets/envelope-paper.svg";
import { ReactComponent as LinkedInIcon } from "../../resources/assets/linkedin.svg";
import { ReactComponent as GitHubIcon } from "../../resources/assets/github.svg";
import { ReactComponent as HomeIcon } from "../../resources/assets/house-door.svg";
import { ReactComponent as MoonIcon } from "../../resources/assets/moon-stars-fill.svg";
import { ReactComponent as SunIcon } from "../../resources/assets/brightness-high-fill.svg";

export const NavBar = () => {
  const { switchTheme, isDark } = useContext(AppColorThemeContext);
  const [menuDisplay, setMenuDisplay] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const handleMenuClick = useCallback(() => {
    setMenuDisplay(menuDisplay => !menuDisplay);
  }, []);
  return (
    <>
      <NavBarContainer
        position="fixed"
        component="nav"
        elevation={isDark ? 0 : 1}>
        <Toolbar>
          {!isMobile && (
            <NavItemsContainer>
              <NavLink to="/">
                <img
                  style={{ width: "6rem", height: "4rem" }}
                  src={logoLight}
                  alt="Dark Mode Logo"
                />
              </NavLink>
              <NavLinkItems>
                <NavItemComponent
                  to="/"
                  Icon={HomeIcon}
                  text="Home"
                />
                <NavItemComponent
                  to="about"
                  Icon={AboutIcon}
                  text="About"
                />
                <Link
                  href="mailto:rumbani@gmail.com"
                  alt="Email"
                  target="_blank"
                  rel="noopener"
                  sx={{ color: "#547fad" }}>
                  <Contact fill="#547fad" />
                </Link>
                <Link
                  href="https://www.linkedin.com/in/williamchavula"
                  alt="LinkedIn"
                  target="_blank"
                  rel="noopener">
                  <LinkedInIcon fill="#547fad" />
                </Link>
                <Link
                  href="https://github.com/WilliamChavula?tab=repositories"
                  alt="Github"
                  target="_blank"
                  rel="noopener">
                  <GitHubIcon fill="#547fad" />
                </Link>
                <NavItemButton
                  aria-label="switch theme"
                  onClick={switchTheme}>
                  {isDark ? <SunIcon /> : <MoonIcon />}
                </NavItemButton>
              </NavLinkItems>
            </NavItemsContainer>
          )}
          {isMobile && (
            <NavbarMobile
              isDark={isDark}
              switchTheme={switchTheme}
              handleMenuClick={handleMenuClick}
              menuDisplay={menuDisplay}
            />
          )}
        </Toolbar>
      </NavBarContainer>
      <Offset />
    </>
  );
};
