import { Container } from "@mui/material";
import { PageContainer } from "../styles";

export const PageContainerComponent = ({ children, props }) => {
  return (
    <PageContainer>
      <Container maxWidth="md" {...props}>
        {children}
      </Container>
    </PageContainer>
  );
};

export default PageContainerComponent;
