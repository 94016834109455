import { Grid } from "@mui/material";

import PortfolioItem from "../PortfolioItem";
import { ProjectSection, SectionTitle } from "./section.styles";

export const SectionComponent = ({ projectsArray, sectionTitle, variant }) => {
  return (
    <ProjectSection>
      <SectionTitle variant={variant}>{sectionTitle}</SectionTitle>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}>
        {projectsArray.map(project => (
          <Grid
            key={project.projectId}
            item
            xs={12}
            sm={6}>
            <PortfolioItem
              bgImage={project.projectImage}
              cardTitle={project.projectTitle}
              cardBody={project.projectOverview}
              projectLinksArray={project.projectLinks}
              projectId={project.projectId}
            />
          </Grid>
        ))}
      </Grid>
    </ProjectSection>
  );
};
